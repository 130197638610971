
import React, { useEffect } from 'react';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useStyles } from '../Bookings/Bookings.style'
import { selectCurrentTempleId } from 'store/reducers/temple/templeSlice';
import { RouteConfigComponentProps } from 'react-router-config';
import FullwidthTableCell from 'components/Table/FullwidthTableCell';
import FullWidthTableCell from 'components/Table/FullwidthTableCell';
import { useMemo } from 'react';import ReportTableToolbar from './ReportTableToolbar';
import ReportTableHead from './ReportTableHead';
import { downloadExcelThunk, listOfferingDownloadPdfThunk, listOfferingFinancialThunk, OfferingFinancialFilterType, selectAllOfferingFinancialData, selectOfferingFinancialApi, selectOfferingFinancialFilterArgs, setOfferingFinancialFilters } from 'store/reducers/temple/offeringFinancialSlice';
import { IOfferingFinancialItemSType, IOffferingFinancialRow } from 'types/api/offeringFinancial';
import { unwrapResult } from '@reduxjs/toolkit';
import NormalTable from 'components/Table/NormalTable';
import { ReceiptRequestTypes } from 'store/reducers/temple/receiptSlice/types';



function createOfferingFinancialRow(of: IOfferingFinancialItemSType): IOffferingFinancialRow {
  let row: IOffferingFinancialRow = {
    deityName:of.deityName,
    offeringName:of.offeringName,
    dakshina:of.dakshina,
    rate:of.rate,
    count:of.count,
    amount:of.amount,
    appCount: of.appCount,
    websiteCount: of.websiteCount,
    posCount: of.posCount,
    counterCount: of.counterCount,
  }
    
  return row
}


export const getOfferingFinancialRows = (
  offeringFinancials: IOfferingFinancialItemSType[],
): IOffferingFinancialRow[] => 
  offeringFinancials
  .map(of => createOfferingFinancialRow(of))



const ReportTable: React.FC<RouteConfigComponentProps> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch()
  const currentTempleId = useAppSelector(selectCurrentTempleId)
  const filterArgs = useAppSelector(selectOfferingFinancialFilterArgs)

  useEffect(() => {
    if (currentTempleId)
      dispatch(listOfferingFinancialThunk(currentTempleId)).then(unwrapResult).then((response)=>console.log(response))
  }, [dispatch, currentTempleId, filterArgs])
  // const [selectedIds, setSelectedIds] = useState<(number | string)[]>([])
  const offeringFinancials = useAppSelector(selectAllOfferingFinancialData)
  // const pagination = useAppSelector(selectPagination)
  const rows = useMemo(() => getOfferingFinancialRows(offeringFinancials.item), [offeringFinancials.item])
  const api = useAppSelector(selectOfferingFinancialApi)

  

  // const handleReceiptDialogClose = () => setReceiptDialog(rd => { return { ...rd, open: false } })
  // const handleReceiptOpen = (id: string | number) => setReceiptDialog({ open: true, id: id })



  const handleRequestSort = (property: keyof IOffferingFinancialRow) => {
  };



  const changeFilter = (requestType: ReceiptRequestTypes, filters: Partial<OfferingFinancialFilterType>) => {
    dispatch(setOfferingFinancialFilters(filters))
    if (currentTempleId && requestType === 'pdf') 
    dispatch(listOfferingDownloadPdfThunk({templeId:currentTempleId}))
  }
  

  const downloadPDF = () => {
    if (currentTempleId)
    dispatch(listOfferingDownloadPdfThunk({templeId:currentTempleId}))
  }

  const downloadExcel = () => {
    if (currentTempleId)
    dispatch(downloadExcelThunk({templeId:currentTempleId}))
  }

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <NormalTable
            tableHead={
              <ReportTableHead
                order={'asc'}
                orderBy={'deityName'}
                onRequestSort={handleRequestSort}
              />
            }
            toolbar={
              <ReportTableToolbar changeFilter={changeFilter} downloadPDF={downloadPDF} downloadExcel={downloadExcel} receiptApi={api} />
            }
            api={api}
            // action={
            //   <Box mx={4}>
            //     <Button
            //       color='primary'
            //       variant='contained'
            //       size='small'
            //       disableElevation
            //       disabled={false}
            //       onClick={() => handleMarkAsComplete()}
            //     ><IntlMessages id="offering.markAsCompleted" /></Button>
            //   </Box>
            // }
          >
            {rows
              .map((row, index) => {
                return (
                  <TableRow
                    key={index}
                  >
                    <FullwidthTableCell align="left">{row.deityName}</FullwidthTableCell>
                    <FullwidthTableCell align="left">{row.offeringName}</FullwidthTableCell>
                    <FullwidthTableCell align="left">{row.rate.toFixed(2)}</FullwidthTableCell>
                    <FullWidthTableCell align="left">{row.appCount}</FullWidthTableCell>
                    <FullWidthTableCell align="left">{row.counterCount}</FullWidthTableCell>
                    <FullWidthTableCell align="left">{row.posCount}</FullWidthTableCell>
                    <FullWidthTableCell align="left">{row.websiteCount}</FullWidthTableCell>
                    <FullWidthTableCell align="left">{row.count}</FullWidthTableCell>
                    <FullWidthTableCell align="left">{row.amount.toFixed(2)}</FullWidthTableCell>
                    <FullWidthTableCell align="left">{row.dakshina.toFixed(2)}</FullWidthTableCell>
                  </TableRow>
                );
              })}
              <TableRow>
              <FullWidthTableCell align="left" colSpan={3} variant='head'> <b>Total</b></FullWidthTableCell>
              <FullWidthTableCell align="left" ><b>{(offeringFinancials?.total ?? 0).appCount}</b></FullWidthTableCell>
              <FullWidthTableCell align="left" ><b>{(offeringFinancials?.total ?? 0).counterCount}</b></FullWidthTableCell>
              <FullWidthTableCell align="left" ><b>{(offeringFinancials?.total ?? 0).posCount}</b></FullWidthTableCell>
              <FullWidthTableCell align="left" ><b>{(offeringFinancials?.total ?? 0).websiteCount}</b></FullWidthTableCell>
              <FullWidthTableCell align="left" ><b>{(offeringFinancials?.total ?? 0).count}</b></FullWidthTableCell>
              <FullWidthTableCell align="left" ><b>{(offeringFinancials?.total?.amount ?? 0).toFixed(2)}</b></FullWidthTableCell>
              <FullWidthTableCell align="left" ><b>{(offeringFinancials?.total?.dakshina ?? 0).toFixed(2)}</b></FullWidthTableCell>

              </TableRow>
          </NormalTable>
        </Paper>
      </div>
    );
  }

export default ReportTable
