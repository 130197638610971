import IntlMessages from '@crema/utility/IntlMessages'
import { MenuItem } from '@material-ui/core'
import FormikTextField from 'components/Fields/FormikTextField'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { deityRetrieveThunk, selectDeityById } from 'store/reducers/temple/deitySlice'
import { offeringListThunk, selectCurrentTempleOfferings, selectOfferingAsMapWithUrl } from 'store/reducers/temple/offeringSlice'

interface BookingsOfferingSelectProp {
    deityId: string | number,
    className: string,
    // handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void,
}
const BookingsOfferingSelect: React.FC<BookingsOfferingSelectProp> = ({ className, deityId }) => {
    const offerings = useAppSelector(selectCurrentTempleOfferings)
    const deity = useAppSelector(state => selectDeityById(state, deityId))
    const offeringMap = useAppSelector(selectOfferingAsMapWithUrl)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(offeringListThunk({}))
    }, [dispatch])
    useEffect(() => {
        if (deityId)
            dispatch(deityRetrieveThunk({ id: deityId }))
    }, [dispatch, deityId])
    return (
        <FormikTextField
            select
            name='offering'
            className={className}
            size="small"
            label={<IntlMessages id='offering' />}
            margin="none"
        // onChange={handleChange}
        >
            <MenuItem value="">None</MenuItem>
            {deity ? deity.offerings && deity.offerings.map(offering => (
                <MenuItem key={offeringMap[offering].id} value={offeringMap[offering].id}>
                    {offeringMap[offering].name}
                </MenuItem>
            )) : offerings.map(offering => (
                <MenuItem key={offering.id} value={offering.id}>
                    {offering.name}
                </MenuItem>
            ))}
        </FormikTextField>
    )
}

export default BookingsOfferingSelect