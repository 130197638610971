import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { deityAPI } from "api/temple";
import { apiStatus } from "shared/constants/AppEnum";
import { AppState } from "store/reduxStore";
import { IDeityResponse, IOfferingResponse, TempleIdType } from "types/api/temple";
import { IApiState } from "store/types";
import { IDeityType } from "types/store/temple";
import { selectCurrentTempleId } from "./templeSlice";

const deityAdapter = createEntityAdapter<IDeityResponse>({
    sortComparer: (a, b) => a.name.localeCompare(b.name)
})

type addOnInitialStateType = {
    api: IApiState,
    apiOfferings: IApiState,
    apiImg: IApiState
}

const addOnIntitalState: addOnInitialStateType = {
    api: {
        status: apiStatus.IDLE,
        error: null
    },
    apiOfferings: {
        status: apiStatus.IDLE,
        error: null
    },
    apiImg: {
        status: apiStatus.IDLE,
        error: null
    },
}

export const deityListThunk = createAsyncThunk(
    'deity/list',
    async (payload: {}, { rejectWithValue, getState }) => {
        let templeId = selectCurrentTempleId(getState() as AppState)
        try {
            const response = await deityAPI.list(templeId)
            return response.data.map(deity => {
                deity.templeId = templeId
                return deity
            })
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const uploadImgThunk = createAsyncThunk(
    'deity/img/upload',
    async (payload: IdAndUrlType & { file: File }, { rejectWithValue }) => {
        try {
            const response = await deityAPI.uploadImg(payload.url, payload.file, payload.id)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const deityDeleteThunk = createAsyncThunk(
    'deity/delete',
    async (payload: IdAndUrlType) => {
        await deityAPI.delete(payload.url)
        return payload.id
    }
)

export const deityRetrieveThunk = createAsyncThunk(
    'deity/retrieve',
    async (payload: IdOrUrlType & TempleIdType, { rejectWithValue, getState }) => {
        let templeId = payload.templeId ?? selectCurrentTempleId(getState() as AppState)
        let params
        if ('id' in payload && payload.id)
            params = { itemId: payload.id }
        else if ('url' in payload)
            params = { url: payload.url }
        else
            return rejectWithValue({ detail: 'Identifier not provided' })
        try {
            const response = await deityAPI.get(params, templeId)
            response.data.templeId = templeId
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const deityUpdateThunk = createAsyncThunk(
    'deity/update',
    async (payload: PayloadWithUrl<Partial<IDeityType>>, { rejectWithValue }) => {
        try {
            const response = await deityAPI.update(payload.url, payload.data)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const deityCreateThunk = createAsyncThunk(
    'deity/create',
    async (payload: {data: IDeityType, templeId?: string|number}, { rejectWithValue, getState }) => {
        let templeId = payload.templeId ?? selectCurrentTempleId(getState() as AppState)
        try {
            const response = await deityAPI.post(payload.data, templeId)
            response.data.templeId = templeId
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

type deityOfferingThunkPayloadType = {
    url: string,
    offerings: string[]
}

// export const deityAddOfferingThunk = createAsyncThunk(
//     'deity/offerings/add',
//     async (payload: deityOfferingThunkPayloadType, { rejectWithValue }) => {
//         try {
//             const response = await deityAPI.addOfferings(
//                 payload.url, payload.offerings.map(offering => offering.url)
//             )
//             return response.data
//         } catch (err:any) {
//             return rejectWithValue(err.response.data)
//         }
//     }
// )

// export const deityRemoveOfferingThunk = createAsyncThunk(
//     'deity/offerings/remove',
//     async (payload: deityOfferingThunkPayloadType, { rejectWithValue }) => {
//         try {
//             const response = await deityAPI.removeOfferings(
//                 payload.url, payload.offerings.map(offering => offering.id)
//             )
//             return response.data
//         } catch (err:any) {
//             return rejectWithValue(err.response.data)
//         }
//     }
// )

const deitySlice = createSlice({
    name: 'deity',
    initialState: deityAdapter.getInitialState(addOnIntitalState),
    reducers: {
        resetState(state) {
            state.api = {
                status: apiStatus.IDLE,
                error: null
            }
            state.apiOfferings = {
                status: apiStatus.IDLE,
                error: null
            }
            deityAdapter.removeAll(state)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deityListThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(deityListThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            deityAdapter.upsertMany(state, action.payload)
        })
        builder.addCase(deityListThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(deityRetrieveThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(deityRetrieveThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            deityAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(deityRetrieveThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(deityDeleteThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(deityDeleteThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            deityAdapter.removeOne(state, action.payload)
        })
        builder.addCase(deityDeleteThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.error.message
            }
        })
        builder.addCase(deityUpdateThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(deityUpdateThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            deityAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(deityUpdateThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(deityCreateThunk.pending, (state, action) => {
            state.api = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(deityCreateThunk.fulfilled, (state, action) => {
            state.api = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            deityAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(deityCreateThunk.rejected, (state, action) => {
            state.api = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        // builder.addCase(deityAddOfferingThunk.pending, (state, action) => {
        //     state.apiOfferings = {
        //         status: apiStatus.LOADING,
        //         error: null
        //     }
        // })
        // builder.addCase(deityAddOfferingThunk.fulfilled, (state, action) => {
        //     state.apiOfferings = {
        //         status: apiStatus.SUCCEEDED,
        //         error: null
        //     }
        //     deityAdapter.upsertOne(state, action.payload)
        // })
        // builder.addCase(deityAddOfferingThunk.rejected, (state, action) => {
        //     state.apiOfferings = {
        //         status: apiStatus.FAILED,
        //         error: action.payload
        //     }
        // })
        // builder.addCase(deityRemoveOfferingThunk.pending, (state, action) => {
        //     state.apiOfferings = {
        //         status: apiStatus.LOADING,
        //         error: null
        //     }
        // })
        // builder.addCase(deityRemoveOfferingThunk.fulfilled, (state, action) => {
        //     state.apiOfferings = {
        //         status: apiStatus.SUCCEEDED,
        //         error: null
        //     }
        //     deityAdapter.upsertOne(state, action.payload)
        // })
        // builder.addCase(deityRemoveOfferingThunk.rejected, (state, action) => {
        //     state.apiOfferings = {
        //         status: apiStatus.FAILED,
        //         error: action.payload
        //     }
        // })
        builder.addCase(uploadImgThunk.pending, (state, action) => {
            const loadingState = {
                status: apiStatus.LOADING,
                error: null
            }
            state.apiImg = loadingState
        })
        builder.addCase(uploadImgThunk.fulfilled, (state, action) => {
            const deity = deityAdapter
                .getSelectors()
                .selectById(state, action.meta.arg.id)
            if (deity)
                deityAdapter.upsertOne(state, { ...deity, ...action.payload })
            const successState = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            state.apiImg = successState
        })
        builder.addCase(uploadImgThunk.rejected, (state, action) => {
            const errorState = {
                status: apiStatus.FAILED,
                error: action.payload
            }
            state.apiImg = errorState
        })
    }
})

export default deitySlice.reducer


export const { resetState } = deitySlice.actions

export const {
    selectAll: selectAllDeity,
    selectById: selectDeityById,
    selectIds: selectDeityIds,
} = deityAdapter.getSelectors((state: AppState) => state.deity)

export const selectDeityStatus = (state: AppState) => state.deity.api
export const selectCurrentTempleDeities = createSelector(
    [selectAllDeity, selectCurrentTempleId],
    (deities, currentTemple) => deities.filter(deity => deity.templeId === currentTemple)
)
export const selectDeitiesByTempleId = createSelector(
    (state: AppState) => state, 
    (_:any, templeId: string|number) => templeId,
    (state, templeId) => {
        if (!templeId) return
        return selectAllDeity(state).filter(deity => deity.templeId === templeId)
    } 
)
export const selectImgUploadApi = (state: AppState) => state.deity.apiImg
