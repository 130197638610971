import IntlMessages from '@crema/utility/IntlMessages'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { FieldArrayRenderProps, FormikErrors } from 'formik'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { CremaTheme } from 'types/crema/AppContextPropsType'
import { CurrentEditingTimeType, IOfferingFormTimesType, IOfferingFormTimeType } from 'types/store/temple'
import FormBlock from '../../../components/Form/FormBlock'
import RedButton from 'components/Button/RedButton'
import FormikTextField from 'components/Fields/FormikTextField'
import { IOfferingResponse } from 'types/api/temple'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { offeringTimeAddThunk, offeringTimeDeleteThunk, offeringTimeUpdateThunk, selectOfferingTimeStatus } from 'store/reducers/temple/offeringSlice'
import { formToOfferingTime, getTimeChipText, offeringTimeToForm } from 'utils/temple/offering'
import { unwrapResult } from '@reduxjs/toolkit'
import { extractError, getEditedPayload, isFieldError } from 'utils/api'
import { Alert } from '@material-ui/lab'
import { apiStatus } from 'shared/constants/AppEnum'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        chipRoot: {
            margin: "4px 8px"
        }
    })
)

interface OfferingTimesProps {
    formContext: string,
    formState: IOfferingFormTimesType,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    errors?: FormikErrors<IOfferingFormTimesType>,
    arrayHelpers: FieldArrayRenderProps,
    offering?: IOfferingResponse
}

const OfferingTimes: React.FC<OfferingTimesProps> = props => {
    const { formContext, formState, setFieldValue, errors, arrayHelpers, offering } = props
    const { messages } = useIntl();
    const dispatch = useAppDispatch()
    const classes = useStyles()
    const [currentOfferingTime, setCurrentOfferingTime] = useState<IOfferingFormTimeType>()
    const api = useAppSelector(selectOfferingTimeStatus)

    const emptyState: CurrentEditingTimeType = {
        fromTime: '',
        toTime: '',
        blockCalcChooser: formState.currentEditing.blockCalcChooser,
        maxLimit: '',
        index: '',
        url: '',
        id: ''
    }
    const areTimesSame = () =>
        formState.currentEditing.fromTime &&
        formState.currentEditing.toTime &&
        formState.currentEditing.fromTime.toTimeString() === formState.currentEditing.toTime.toTimeString()

    const isButtonDisabled = () => Boolean(
        !(formState.currentEditing.fromTime && formState.currentEditing.toTime) ||
        areTimesSame() ||
        errors ||
        formState.currentEditing.toTime <= formState.currentEditing.fromTime
    )

    const handleAdd = () => {
        if (formState.currentEditing.fromTime && formState.currentEditing.toTime) {
            if (!formState.times.find(time => (
                formState.currentEditing.fromTime &&
                formState.currentEditing.toTime &&
                !areTimesSame() &&
                time.toTime &&
                time.fromTime.toTimeString() === formState.currentEditing.fromTime.toTimeString() &&
                time.toTime.toTimeString() === formState.currentEditing.toTime.toTimeString()
            ))) {
                let offeringTime: IOfferingFormTimeType = {
                    fromTime: formState.currentEditing.fromTime,
                    toTime: formState.currentEditing.toTime,
                    blockCalcChooser: formState.currentEditing.blockCalcChooser,
                    maxLimit: formState.currentEditing.maxLimit
                }
                resetForm()
                if (offering) {
                    dispatch(offeringTimeAddThunk({ url: offering.url, data: formToOfferingTime(offeringTime) }))
                        .then(unwrapResult)
                        .then(response => arrayHelpers.push(offeringTimeToForm(response)))
                        .catch(err => { })
                } else arrayHelpers.push(offeringTime)
            }
        }
    }

    const setCurrentEditing = (currentEditing: CurrentEditingTimeType) =>
        setFieldValue(`${formContext}.currentEditing`, currentEditing)

    const handleTime = (index: number, time: IOfferingFormTimeType) => {
        setCurrentOfferingTime(time)
        setCurrentEditing({
            fromTime: time.fromTime,
            toTime: time.toTime,
            blockCalcChooser: time.blockCalcChooser,
            index: index,
            id: time.id,
            url: time.url,
            maxLimit: time.maxLimit
        })
    }

    const resetForm = () => setCurrentEditing(emptyState)

    const updateTime = () => {
        if ((
            formState.currentEditing.index ||
            formState.currentEditing.index === 0) &&
            formState.currentEditing.fromTime &&
            formState.currentEditing.toTime
        ) {
            let offeringTime: IOfferingFormTimeType = {
                id: formState.currentEditing.id,
                url: formState.currentEditing.url,
                fromTime: formState.currentEditing.fromTime,
                toTime: formState.currentEditing.toTime,
                blockCalcChooser: formState.currentEditing.blockCalcChooser,
                maxLimit: formState.currentEditing.maxLimit
            }
            if (offering && currentOfferingTime?.url) {
                arrayHelpers.remove(formState.currentEditing.index)
                let payload = getEditedPayload(formToOfferingTime(currentOfferingTime), formToOfferingTime(offeringTime))
                if (Object.keys(payload).length > 0)
                    dispatch(offeringTimeUpdateThunk({ url: currentOfferingTime.url, data: payload }))
                        .then(unwrapResult)
                        .then(response => {
                            resetForm()
                            arrayHelpers.push(offeringTimeToForm(response))
                        }).catch(err => { })
            } else {
                arrayHelpers.replace(formState.currentEditing.index, offeringTime)
                resetForm()
            }
        }
    }

    const deleteTime = () => {
        if (formState.currentEditing.index || formState.currentEditing.index === 0) {
            if (formState.currentEditing.url)
                dispatch(offeringTimeDeleteThunk({ url: formState.currentEditing.url }))
                    .then(() => {
                        if(formState.currentEditing.index){
                            resetForm()
                            arrayHelpers.remove(formState.currentEditing.index) 
                        }
                    })
                    .catch(err => {})
            else {
                resetForm()
                arrayHelpers.remove(formState.currentEditing.index)
            }
        }
    }

    const isNew = () => !(
        formState.currentEditing.index !== '' ||
        formState.currentEditing.url
    )

    return (
        <FormBlock titleId="offering.offeringTimes.action.title">
            <Grid container spacing={6}>
                <Grid item sm={6}>
                    <KeyboardTimePicker
                        label={messages['common.from']}
                        onChange={time => setFieldValue(`${formContext}.currentEditing.fromTime`, time ?? '')}
                        value={formState.currentEditing.fromTime === '' ?
                            null : formState.currentEditing.fromTime
                        }
                        // format="hh:mm aa"
                        mask="__:__ _M"
                        inputVariant="outlined"
                        size="small"
                        placeholder="hh:mm AM/PM"
                    />
                </Grid>
                <Grid item sm={6}>
                    <KeyboardTimePicker
                        label={messages['common.to']}
                        onChange={time => setFieldValue(`${formContext}.currentEditing.toTime`, time ?? '')}
                        value={formState.currentEditing.toTime === '' ?
                            null : formState.currentEditing.toTime
                        }
                        // format="hh:mm a"
                        mask="__:__ _M"
                        inputVariant="outlined"
                        size="small"
                        placeholder="hh:mm AM/PM"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormikTextField
                        name={`${formContext}.currentEditing.maxLimit`}
                        type='number'
                        variant='outlined'
                        size='small'
                        margin="none"
                        // value={formState.currentEditing.maxLimit}
                        label={<IntlMessages id="offering.offeringTimes.maxLimit" />}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend"><IntlMessages id="offering.offeringTimes.choose" /></FormLabel>
                        <RadioGroup
                            row
                            aria-label="gender"
                            name={`${formContext}.currentEditing.blockCalcChooser`}
                            value={formState.currentEditing.blockCalcChooser}
                            onChange={event => setFieldValue(event.target.name, event.target.value)}
                        >
                            <FormControlLabel
                                value="from_time"
                                control={<Radio />}
                                label={messages['offering.offeringTimes.fromTime']}
                            />
                            <FormControlLabel
                                value="to_time"
                                control={<Radio />}
                                label={messages['offering.offeringTimes.toTime']}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {isNew() ? (
                    <Grid item xs={12}>
                        <Button
                            onClick={() => handleAdd()}
                            disabled={isButtonDisabled()}
                            variant="outlined"
                            size="large"
                        >
                            <IntlMessages id="common.add" />
                        </Button>
                    </Grid>
                ) : (
                    <Grid item container xs={12} spacing={6}>
                        <Grid item>
                            <Button
                                onClick={() => updateTime()}
                                disabled={isButtonDisabled()}
                                variant="outlined"
                                color="primary"
                                size="large"
                            >
                                <IntlMessages id="common.update" />
                            </Button>
                        </Grid>
                        <Grid item>
                            <RedButton
                                onClick={() => deleteTime()}
                                // disabled={isButtonDisabled()}
                                variant="outlined"
                                size="large"
                            >
                                <IntlMessages id="common.delete" />
                            </RedButton>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => resetForm()}
                                variant="outlined"
                                size="large"
                            >
                                <IntlMessages id="common.cancel" />
                            </Button>
                        </Grid>
                    </Grid>
                )}
                {api.status === apiStatus.FAILED && !isFieldError(api.error) && (
                    <Grid item xs={12}>
                        <Alert severity="error">{extractError(api.error)}</Alert>
                    </Grid>
                )}
                {api.status === apiStatus.LOADING && (
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                )}
                <Grid item xs={12}>
                    {formState.times.map((time, index) => (
                        <Chip
                            key={index}
                            label={getTimeChipText(time)}
                            color="primary"
                            variant="outlined"
                            classes={{ root: classes.chipRoot }}
                            onClick={e => handleTime(index, time)}
                        />
                    ))}
                </Grid>
            </Grid>
        </FormBlock>
    )
}

export default React.memo(OfferingTimes)