import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import React, { useState } from 'react';
import { apiStatus, ImageSizes } from 'shared/constants/AppEnum';
import { ITempleResponse } from 'types/api/temple';
import { getImage } from 'utils/fns';
import { templeAddressToStrFn } from 'utils/temple';
import TempleAvatar from '../TempleAvatar';
import TempleStatusChip from '../TempleStatusChip';
import { useStyles } from './style';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import clsx from 'clsx'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import IntlMessages from '@crema/utility/IntlMessages';
import RedButton from 'components/Button/RedButton';
import { IApiState } from 'store/types';

interface TempleInfoCardProps {
    temple: ITempleResponse,
    handleDelete: (data: IdAndUrlType) => void,
    deleteApi: IApiState,
    handleActivation: (data: IdAndUrlType & { shouldActivate: boolean }) => void,
    activationApi: IApiState,
}
const TempleInfoCard: React.FC<TempleInfoCardProps> = 
    ({ temple, handleDelete, deleteApi, handleActivation, activationApi }) => {
    const classes = useStyles()
    const [dialogOpen, setDialogOpen] = useState(false)

    const handlerCloseDialog = (shouldDelete: boolean) => {
        shouldDelete? 
            handleDelete(temple):
            setDialogOpen(false)
    }

    return (
        <React.Fragment>
            <Card>
                <CardHeader
                    avatar={
                        <TempleAvatar temple={temple} />
                    }
                    title={temple.name}
                    subheader={
                        <TempleStatusChip temple={temple} />
                    }
                />
                <CardMedia
                    className={classes.media}
                    image={temple.coverImg ? getImage(temple.coverImg, ImageSizes.MEDIUM) : '/assets/images/placeholders/temple-cover.jpg'}
                />
                <CardContent>
                    {temple.address && (
                        <Typography variant="body2" component="p">
                            {templeAddressToStrFn(temple.address)}
                        </Typography>
                    )}
                    {temple.phone && (
                        <Typography variant="body2" component="p">
                            Phone: +{temple.phone}
                        </Typography>
                    )}
                </CardContent>
                <CardActions disableSpacing>
                    {temple.isActive !== undefined && (
                        <React.Fragment>
                            {temple.isActive ? (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className={clsx(classes.button, classes.disapproveButton)}
                                    startIcon={<WarningIcon />}
                                    size="small"
                                    disableElevation
                                    disabled={activationApi.status === apiStatus.LOADING}
                                    onClick={() => handleActivation({...temple, shouldActivate: false})}
                                >
                                    Deactivate
                                </Button>
                            ) : (
                                <React.Fragment>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={clsx(classes.button, classes.approveButton)}
                                        startIcon={<CheckIcon />}
                                        size="small"
                                        disableElevation
                                        disabled={activationApi.status === apiStatus.LOADING}
                                        onClick={() => handleActivation({...temple, shouldActivate: true})}
                                    >
                                        Activate
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={clsx(classes.button, classes.deleteButton)}
                                        startIcon={<RemoveCircleIcon />}
                                        size="small"
                                        disableElevation
                                        onClick={() => setDialogOpen(true)}
                                    >
                                        Delete Temple
                                    </Button>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}

                </CardActions>
            </Card>
            <Dialog open={dialogOpen}>
                <DialogTitle>{`Are you sure you want to delete this temple?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Warning: This action is irreversible
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => handlerCloseDialog(false)}>
                        <IntlMessages id="common.cancel" />
                    </Button>
                    <RedButton disabled={deleteApi.status===apiStatus.LOADING} variant="outlined" onClick={() => handlerCloseDialog(true)} autoFocus>
                        <IntlMessages id="common.delete" />
                    </RedButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};


export default TempleInfoCard;