import React, { useEffect } from 'react'
import { ReceiptFilterFormType } from 'types/store/receipt';
// import { useIntl } from 'react-intl';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { CremaTheme } from 'types/crema/AppContextPropsType';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deityListThunk, selectCurrentTempleDeities } from 'store/reducers/temple/deitySlice';
import Button from '@material-ui/core/Button';
import { ReceiptFilterArgsType, ReceiptRequestTypes } from 'store/reducers/temple/receiptSlice/types';
import { IApiState } from 'store/types';
import { formToFilterArgs } from 'utils/temple/receipt';
import ReportsfilterForm from './ReportsfilterForm';
import IntlMessages from '@crema/utility/IntlMessages';
import { apiStatus } from 'shared/constants/AppEnum';
import NormalToolbar from 'components/Table/NormalTable/NormalToolbar';
import { selectOfferingFinancialDuration, setOfferingFinancialDuration } from 'store/reducers/temple/offeringFinancialSlice';

interface EnhancedTableToolbarProps {
    changeFilter: (requestType: ReceiptRequestTypes, filters: Partial<ReceiptFilterArgsType>) => void,
    downloadPDF: () => void,
    downloadExcel: () => void,
    receiptApi: IApiState,
}


const useStyles = makeStyles((theme: CremaTheme) => (
    createStyles({
        button: {
            // width: '200px',
            // height: '30px',
            fontSize: '12px',
            marginRight: '10px',
            marginBottom: '10px'
        },
        toolbar: {
            padding: '0 17px'
        },
        toolbarButtons: {
            // display: 'flex',
            // justifyContent: 'space-between'
        },
        field: {
            flexGrow: 1
        },
        fieldRoot: {
            // width: 180,
            // [theme.breakpoints.down('sm')]: {
            //   width: '100%'
            // },
            width: '100%'
        },
        hidden: {
            display: "none"
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        formGridRoot: {
            [theme.breakpoints.down('sm')]: {
                flexDirection: "column"
            },
        }
    })
))



const ReportTableToolbar: React.FC<EnhancedTableToolbarProps> = ({ changeFilter, downloadPDF, downloadExcel, receiptApi }) => {
    const durationType = useAppSelector(selectOfferingFinancialDuration)
    // const { messages } = useIntl();
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const deities = useAppSelector(selectCurrentTempleDeities)

    useEffect(() => {
        dispatch(deityListThunk({}))
    }, [dispatch])


    return (
        <NormalToolbar titleId="report.title" showFilters={true}>
            <Grid className={classes.toolbar} container spacing={6} >
                <Grid className={classes.toolbarButtons} item xs={12} spacing={6}  >
                    <Button
                        className={classes.button}
                        variant={durationType === 'today' ? 'contained' : 'outlined'}
                        color='primary'
                        onClick={() => dispatch(setOfferingFinancialDuration('today'))}
                    >Today</Button>
                    <Button
                        className={classes.button}
                        variant={durationType === 'thisMonth' ? 'contained' : 'outlined'}
                        color='primary'
                        onClick={() => dispatch(setOfferingFinancialDuration('thisMonth'))}
                    >This Month</Button>
                    <Button
                        className={classes.button}
                        variant={durationType === 'lastMonth' ? 'contained' : 'outlined'}
                        color='primary'
                        onClick={() => dispatch(setOfferingFinancialDuration('lastMonth'))}
                    >Last Month</Button>
                    <Button
                        className={classes.button}
                        variant={durationType === 'thisYear' ? 'contained' : 'outlined'}
                        color='primary'
                        onClick={() => dispatch(setOfferingFinancialDuration('thisYear'))}
                    >This Year</Button>
                    <Button
                        className={classes.button}
                        variant={durationType === '' ? 'contained' : 'outlined'}
                        color='primary'
                        onClick={() => dispatch(setOfferingFinancialDuration(''))}
                    >Custom</Button>
                </Grid>
                {durationType === '' && (
                    <Grid container item xs={12}>
                        <ReportsfilterForm deities={deities} receiptApi={receiptApi}
                            handleFormChange={
                                (requestType: ReceiptRequestTypes, values: ReceiptFilterFormType) => {
                                    changeFilter(requestType, formToFilterArgs(values))
                                }} 
                        />
                    </Grid>
                )}

                {durationType !== '' && (
                    <Grid item style={{paddingTop: 0}}>
                        <Button name="buttonPDF" disabled={receiptApi.status === apiStatus.LOADING} variant="outlined" size="small" color="primary"
                            onClick={() => downloadPDF()}
                        >
                            <IntlMessages id="common.download.pdf" />
                        </Button>
                    </Grid>
                )}
                 {durationType !== '' && (
                    <Grid item style={{paddingTop: 0}}>
                        <Button disabled={receiptApi.status === apiStatus.LOADING} variant="outlined" size="small" color="primary"
                            onClick={() => downloadExcel()}
                        >
                            <IntlMessages id="common.download.excel" />
                        </Button>
                    </Grid>
                )}
            </Grid>
        </NormalToolbar>
    );
};


export default React.memo(ReportTableToolbar)